import '../../css/pages/homepage.scss'
import { DOMReady } from '../lib/domready'

async function initializeSlider(slider, options) {
  const { attachSlider } = await import('../components/slider')

  return attachSlider(slider, options)
}

DOMReady(() => {
  initializeSlider('#actus-nautiques', {
    perView: 3,
    breakpoints: {
      576: {
        perView: 1,
        dragThreshold: 120,
        peek: { before: 30, after: 30 },
      },
      992: {
        perView: 2,
      },
    },
    peek: { before: 0, after: 0 },
  })

  initializeSlider('#carousel-small', {
    perView: 3,
    autoplay: false,
    peek: { before: 0, after: 0 },
    breakpoints: {
      992: {
        perView: 2,
      },
      576: {
        perView: 1,
        peek: { before: 50, after: 50 },
      },
    },
  })

  initializeSlider('#carousel-recent-sailing')
  initializeSlider('#carousel-recent-motor')

  document.querySelectorAll('[data-notification-close]').forEach((element) => {
    element.addEventListener('click', (eArg) => {
      let url = eArg.target.dataset.url
      element.closest('[data-notification-item]').remove()
      fetch(url, { method: 'DELETE' })
        .then(() => {})
        .catch((err) => {
          console.error(err)
        })
    })
  })
})

document.addEventListener('home-search-fragment-loaded', (event) => {
  if (
    document.getElementById('_search_cta_previous_search').innerHTML.trim() !==
    ''
  ) {
    document.getElementById('home_search').classList.add('d-none')
    document
      .getElementById('home_cancel_current_search')
      .addEventListener('click', () => {
        document.getElementById('home_search').classList.remove('d-none')
        document.getElementById('_search_cta_previous_search').remove()
      })
  }
})
